import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from "react-router-dom";
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import { ConnectedRouter } from 'connected-react-router';
import LoginPage from './pages/auth/LoginPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import NewPasswordPage from './pages/auth/NewPasswordPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import SelectProfilePage from './pages/auth/SelectProfilePage';
import ProfileDetailPage from './pages/auth/ProfileDetailPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import NotFoundPage from './pages/auth/NotFoundPage';
import TrialAccountExpirePage from './pages/auth/TrialAccountExpirePage';
import QueuePage from './pages/dashboard/QueuePage';
import SearchPatientPage from './pages/patient/SearchPatientPage';
import ListMedicinePage from './pages/medicine/ListMedicinePage';
import AddPatientPage from './pages/patient/AddPatientPage';
import ShowPatientPage from './pages/patient/ShowPatientPage';
import ApprovePrescriptionPage from './pages/prescription/ApprovePrescriptionPage';
import AddPrescriptionOrderPage from './pages/pharmacy/AddPrescriptionOrderPage';
import SummaryPrescriptionOrderPage from './pages/pharmacy/SummaryPrescriptionOrderPage';
import SearchPrescriptionPage from './pages/pharmacy/SearchPrescriptionPage';
import ListPatientPage from "./pages/doctor/ListPatientPage";
import ListPrescriptionPage from "./pages/prescription/ListPrescriptionPage";
import ListPrescriptionOrderPage from "./pages/prescription/ListPrescriptionOrderPage";
import ViewPrescriptionOrderPage from "./pages/prescription/ViewPrescriptionOrderPage";
import PharmacyEditPrescriptionPage from "./pages/pharmacy/PharmacyEditPrescriptionPage";
import EditPatientPage from './pages/patient/EditPatientPage';
import ListCommissionsPage from './pages/commission/ListCommissionPage';
import UserManagement from './pages/user-management/UserManagement';
import SearchPatientPathologyTest from './pages/pathology/SearchPatientPathologyTest';
import SearchPatientRadiologyTest from './pages/radiology/SearchPatientRadiologyTest';
import AddToQueuePathologyPage from './pages/pathology/AddToQueuePathology';
import AddToQueueRadiologyPage from './pages/radiology/AddToQueueRadiologyPage';
import ListPathologyOrderPage from './pages/pathology/ListPathologyOrderPage';
import ListRadiologyOrderPage from './pages/radiology/ListRadiologyOrderPage';
import ViewPathologyOrder from './pages/pathology/AddReportsPage';
import ViewRadiologyOrder from './pages/radiology/AddReportsPage';
import PathologyPrintPage from './pages/pathology/PrintPrescriptionPage';
import RadiologyPrintPage from './pages/radiology/PrintPrescriptionPage';
import ViewPrescription from './pages/pharmacy/ViewPrescription';
import TechnicianPrescriptionList from './pages/pharmacy/TechnicianPrescriptionListPage';
import TechnicianPrescriptionOrderPage from './pages/pharmacy/TechnicianPrescriptionOrderPage';
import EditPrescriptionOrderPage from './pages/pharmacy/EditPrescriptionOrderPage';
import ListPrescriptionQueuePage from './pages/pharmacy/ListPrescriptionQueuePage';
import NotificationList from './pages/Notification-list';
import OperatePrescriptionOrderPage from './pages/pharmacy/OperatePrescriptionOrderPage';
import PermissionDeniedPage from './pages/auth/PermissionDeniedPage';
import AssignCardPage from './pages/patient/AssignCardPage';
import CreateOfflinePrescritpion from './pages/CreateOfflinePrescription';
import ViewOfflinePrescription from './pages/pharmacy/ViewOfflinePrescription';
import CreateOfflineOrders from './pages/pharmacy/CreateOfflineOrders';
import StartOfflinePathologyTest from './pages/pathology/StartOfflinePrescriptionTest';
import StartOfflineRadiologyTest from './pages/radiology/StartOfflinePrescriptionTest';
import ContextHelp from './pages/ContextHelp';
import RequestPending from './pages/prescription/RequestPending';
import UserAccounts from './pages/account_and_billing/UserAccounts';
import ListPrescriptionFeesPage from './pages/prescription/ListPrescriptionFeesPage';
import DrugPriceListPage from './pages/pricing/DrugPriceListPage';
import PathologyTestPriceListPage from './pages/pricing/PathologyTestPriceListPage';
import PathologyLimsTestListPage from './pages/pathology/PathologyLimsTestListPage';
import EditPathologyLimsTestPage from './pages/pathology/EditPathologyLimsTestPage';
import AddPathologyLimsTestPage from './pages/pathology/AddPathologyLimsTestPage';
import UploadPathologyLimsTestPage from './pages/pathology/UploadPathologyLimsTestPage';
import UploadLimsTestPricePage from './pages/pathology/UploadLimsTestPricePage';
import RadiologyTestPriceListPage from './pages/pricing/RadiologyTestPriceListPage';
import RissueCardsPage from './pages/doctor/ReissueCardsPage';
import ReturnedOrderList from './pages/pharmacy/ReturnOrderList';
import UserActivityLogPage from './pages/UserActivityLogPage';
import ImmunizationPage from './pages/Immunization/ImmunizationPage';
import ListImmunizationPage from './pages/Immunization/ListImmunizationPage';
import ViewPatientImmunizationPage from './pages/Immunization/ViewPatientImmunizationPage';
import RedirectPage from './pages/RedirectPage';
import SignaturePendingPrescription from './pages/prescription/SignaturePendingPrescription';
import SignaturePendingModifiedPrescriptions from './pages/pharmacy/SignaturePendingModifiedPrescriptions';
import SignaturePendingOrders from './pages/pharmacy/SignaturePendingOrders';
import AddPrescriptionPageNew from './pages/prescription/AddPrescriptionPageNew';
import AddUrgentCarePageNew from './pages/urgent-care/AddUrgentCarePageNew';
import PrescriptionSummaryPageNew from './pages/prescription/PrescriptionSummaryPageNew';
import ViewDoctorPrescriptionPage from './pages/prescription/ViewDoctorPrescriptionPage';
import EditPrescriptionNew from './pages/prescription/EditPrescriptionNew';
import EditPrescriptionSummary from './pages/prescription/EditPrescriptionSummary'
import AddPharmacyPrescriptionPage from './pages/pharmacy/AddPrescriptionPage';
import ListEmergencyPrescriptionPage from './pages/pharmacy/ListEmergencyPrescriptionPage';
import ViewEmergencyPrescription from './pages/prescription/ViewEmergencyPrescription';
import Certificate from './pages/certificate/ListCertificatePage';
import AddCertificate from './pages/certificate/AddCertificatePage';
import PharmacyPrescriptionsPage from './pages/pharmacy/PharmacyPrescriptionsPage';
import OrderReminderList from './pages/pharmacy/OrderReminderList';
import AddOtherImmunizationPage from './pages/Immunization/AddOtherImmunizationPage';
import ListAppointmentPage from './pages/appointment/ListAppointmentPage';
import CreateSlotPage from './pages/slot-management/CreateSlotPage';
import SearchSlotPage from './pages/slot-management/SearchSlotPage';
import HolidayListPage from './pages/slot-management/HolidayListPage';
import AmbulanceSearchPatientPage from './pages/ambulance/SearchPatientPage';
import AddAmbulanceRecordPage from './pages/ambulance/AddRecordPage';
import EditAmbulanceRecordPage from './pages/ambulance/EditRecordPage';
import ListAmbulanceRecordPage from './pages/ambulance/ListRecordPage';
import ViewAmbulanceRecordPage from './pages/ambulance/ViewRecordPage';
import HospitalListPatientPage from './pages/hospital/ListPatientPage';
import ViewPatientRecordPage from './pages/hospital/ViewPatientRecordPage';
import AddRecordPrescriptionPage from './pages/hospital/AddRecordPrescriptionPage';
import SummaryRecordPrescriptionPage from './pages/hospital/SummaryRecordPrescriptionPage';
import ImportHistoricDataPage from './pages/doctor/ImportHistoricDataPage';
import CovidPatientsPage from './pages/covid-center/ShowPatientPage';
import CovidPendingReportPage from './pages/covid-center/CovidPendingReportPage';
import CovidReportGeneratePage from './pages/covid-center/CovidReportGeneratePage';
import CovidCompletedReportPage from './pages/covid-center/CovidCompletedReportPage';
import CovidUploadedReportPage from './pages/covid-center/CovidUploadedReportPage';
import CovidTestPatientsPage from './pages/covid-center/CovidTestPatientsPage';
import CovidPatientDetailsPage from './pages/covid-center/CovidPatientDetailsPage';
import PaymentDetailsPage from './pages/covid-center/PaymentDetailsPage';
import AirlineSearchPatientPage from './pages/airline/SearchPatientPage';
import AirlineViewPatientPage from './pages/airline/ViewPatientPage';
import AirlinePastScanRecordPage from './pages/airline/PastScanRecordPage';
import DraftPrescriptionsPage from './pages/prescription/DraftPrescriptionsPage';
import ListUrgentCarePage from './pages/urgent-care/ListUrgentCarePage';
import ListProcedurePage from './pages/procedure/ListProcedurePage';
import DataManagement from './pages/data-management/DataManagement';
import ListPatientConfirmEditPage from './pages/patient-confirm-edit/ListPatientConfirmEditPage';
import EditPatientConfirmPage from './pages/patient/EditPatientConfirmPage';
import QrCode from './pages/QrCode';
import ListPreRegisteredPatientPage from './pages/ListPreRegisteredPatientPage';
import { hasRole } from './utils/auth';
import TempDoctorSearchPatientPage from './pages/temp-doctor/SearchPatientPage';
import TempDoctorListPatientPage from "./pages/temp-doctor/ListPatientPage";
import TempDoctorVerifiedReportListPage from './pages/temp-doctor/VerifiedReportListPage';
import TempDoctorReportListPage from './pages/temp-doctor/ReportListPage';
import ListDailyPatientPage from './pages/doctor/ListDailyPatientPage';
import LogPatientPage from './pages/doctor/LogPatientPage';
import ListLimsTestPricePage from './pages/lims/ListLimsTestPricePage';
import LabReportDateEntryClerk from './pages/pathology/LabReportDateEntryClerk';
import DraftLabReport from './pages/pathology/DraftLabReport';
import LabReportMedTech from './pages/pathology/LabReportMedTech';
import CompletedReportDetailPage from './pages/pathology/CompletedReportDetailPage';
import PatientReferralList from './pages/pathology/PatientReferralList';
import DraftPatientReferralList from './pages/pathology/DraftPatientReferralList';
import PendingPatientReport from './pages/pathology/PendingPatientReport';
import ProcessingPatientReport from './pages/pathology/ProcessingPatientReport';
import CompletedPatientReport from './pages/pathology/CompletedPatientReport';
import ListTestDoneReport from './pages/pathology/ListTestDoneReport';
import PrintedPatientReport from './pages/pathology/PrintedPatientReport';
import AddReferralForm from './pages/pathology/AddReferralForm';
import ListTestFeesPage from './pages/lims/ListTestFeesPage';
import ListOnlyPrescriptionPage from './pages/prescription/ListOnlyPrescriptionPage';
import LimsReportDetailPage from './pages/pathology/LimsReportDetailPage';
import DaisyHealthUsersPage from './pages/doctor/DaisyHealthUsersPage';

function App({ history, checked, selected_user_profile }) {
  return (
    <ConnectedRouter history={history}>
      {checked &&
        <Suspense fallback={<div> Loading... </div>}>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <PublicLayout path="/login" exact component={LoginPage} />
            <PublicLayout path="/forgot-password" exact component={ForgotPasswordPage} />
            <PublicLayout path="/reset-password" exact component={ResetPasswordPage} />
            <PublicLayout path="/redirect" exact component={RedirectPage} />
            <PublicLayout path="/new-password" exact component={NewPasswordPage} />
            <PrivateLayout path='/select-profile' exact component={SelectProfilePage} />
            <PrivateLayout path='/edit-profile' exact component={ProfileDetailPage} />
            <PrivateLayout path="/dashboard" exact component={DashboardPage} />
            <PrivateLayout path="/change-password" exact component={ChangePasswordPage} />
            <PrivateLayout path="/notification-list" exact component={NotificationList} />
            <PrivateLayout path="/activity-logs" exact component={UserActivityLogPage} allowRoles={['covid-center-owner', 'pathology', 'pathology-manager', 'pathologist', 'pathology-receptionist', 'pathology-phlebotomist', 'radiology', 'radiologist', 'radiology-receptionist', 'doctor', 'associate-doctor', 'medical-practice-manager', 'doctor-receptionist', 'doctor-nurse', 'junior-doctor', 'pharmacy', 'pharmacy-manager', 'pharmacy-technician', 'pharmacist', 'airline-owner', 'pathology-med-tech', 'data-entry-clerk', 'pathology-phlebotomist', 'pathology-lab-tech']} />

            {/* Common module routes */}

            <PrivateLayout path="/user-management" exact component={UserManagement} allowRoles={['doctor', 'pharmacy', 'radiology', 'pathology', 'pathologist', 'pathology-manager', 'pharmacy-manager', 'medical-practice-manager', 'ambulance-admin', 'hospital-admin', 'covid-center-owner', 'airline-owner']} />
            <PrivateLayout path="/commission-reports" exact component={ListCommissionsPage} allowRoles={['doctor', 'pharmacy', 'radiology', 'pathology', 'associate-doctor', 'pharmacy-manager']} />

            {/* Common module routes */}

            {/* Doctor module routes */}
            <PrivateLayout path="/slots" exact component={SearchSlotPage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor', 'medical-practice-manager', 'doctor-nurse', 'doctor-receptionist']} />
            <PrivateLayout path="/slots/create" exact component={CreateSlotPage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor', 'medical-practice-manager', 'doctor-nurse', 'doctor-receptionist']} />
            <PrivateLayout path="/slots/locations/:locationId/holidays" exact component={HolidayListPage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor', 'medical-practice-manager', 'doctor-nurse', 'doctor-receptionist']} />
            <PrivateLayout path="/appointments" exact component={ListAppointmentPage} allowProviders={['doctor']} />
            <PrivateLayout path="/patients/:patientId/immunizations" exact component={ImmunizationPage} allowProviders={['doctor']} />
            <PrivateLayout path="/patients/:patientId/other-immunizations" exact component={AddOtherImmunizationPage} allowProviders={['doctor']} />
            <PrivateLayout path="/doctor-queues" exact component={QueuePage} allowProviders={['doctor']} />
            <PrivateLayout path="/patients/create" exact component={AddPatientPage} allowProviders={['doctor', 'covid-center', 'pathology']} />
            <PrivateLayout path="/patients/search" exact component={SearchPatientPage} allowProviders={['doctor', 'covid-center', 'pathology']} />
            <PrivateLayout path="/patients/:patientId" exact component={ShowPatientPage} allowProviders={['doctor', 'hospital', 'covid-center', 'pathology']} />
            <PrivateLayout path="/medicines" exact component={ListMedicinePage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor']} />
            <PrivateLayout path="/patients" exact component={ListPatientPage} allowProviders={['doctor', 'covid-center', 'pathology']} />
            <PrivateLayout path="/prescriptions" exact component={ListPrescriptionPage} allowProviders={['doctor']} />
            <PrivateLayout path="/prescriptions/draft" exact component={DraftPrescriptionsPage} allowProviders={['doctor']} />
            <PrivateLayout path="/patient/:patientId/edit" exact component={EditPatientPage} allowProviders={['doctor', 'pathology']} />
            <PrivateLayout path="/approve-prescription" exact component={ApprovePrescriptionPage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor']} />
            <PrivateLayout path="/request-pending" exact component={RequestPending} allowProviders={['doctor', 'hospital']} allowRoles={['doctor', 'associate-doctor', 'paramedic-doctor']} />
            <PrivateLayout path="/reissue-cards" exact component={RissueCardsPage} allowProviders={['doctor', 'pathology']} />
            <PrivateLayout path="/assign-card/:request_id" exact component={AssignCardPage} allowProviders={['doctor', 'pathology']} />
            <PrivateLayout path="/prescriptions/fees" exact component={ListPrescriptionFeesPage} allowProviders={['doctor']} allowRoles={['doctor', 'doctor-receptionist', 'doctor-nurse', 'medical-practice-manager', hasRole(selected_user_profile, ['associate-doctor']) && selected_user_profile.is_procedure_listed ? 'associate-doctor' : '']} />
            <PrivateLayout path="/immunization-queues" exact component={ListImmunizationPage} allowProviders={['doctor','associate-doctor']} />
            <PrivateLayout path="/patients/:patientId/immunizations/view" exact component={ViewPatientImmunizationPage} allowProviders={['doctor']} />
            <PrivateLayout path="/prescriptions/pending-signature" exact component={SignaturePendingPrescription} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor']} />
            <PrivateLayout path="/patients/:patientId/prescriptions/:prescriptionId" exact component={AddPrescriptionPageNew} allowProviders={['doctor']} />
            <PrivateLayout path="/patients/:patientId/urgent-care/:prescriptionId" exact component={AddUrgentCarePageNew} allowProviders={['doctor']} />
            <PrivateLayout path="/prescriptions/:prescriptionId/summary" exact component={PrescriptionSummaryPageNew} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor']} />
            <PrivateLayout path="/prescriptions/:prescriptionId" exact component={ViewDoctorPrescriptionPage} allowProviders={['doctor', 'hospital']} allowRoles={['doctor', 'associate-doctor', 'paramedic-doctor', 'junior-doctor','medical-practice-manager']} />
            <PrivateLayout path="/prescriptions/:prescriptionId/edit" exact component={EditPrescriptionNew} allowProviders={['doctor']} />
            <PrivateLayout path="/prescriptions/:prescriptionId/edit-summary" exact component={EditPrescriptionSummary} allowProviders={['doctor']} />
            <PrivateLayout path="/doctor/prescriptions/:prescriptionId/view" exact component={ViewEmergencyPrescription} allowProviders={['doctor']} />
            <PrivateLayout path="/patients/:patientId/medical-certificate" exact component={Certificate} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor']} />
            <PrivateLayout path="/patients/:patientId/medical-certificate/:type" exact component={AddCertificate} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor']} />
            <PrivateLayout path="/historical-data" exact component={ImportHistoricDataPage} allowProviders={['doctor']} />
            <PrivateLayout path="/patient/daily-list" exact component={ListDailyPatientPage} allowProviders={['doctor']} allowRoles={["doctor", "doctor-receptionist", "doctor-nurse", "medical-practice-manager", 'associate-doctor']}/>
            <PrivateLayout path="/patient/edit-log" exact component={LogPatientPage} allowProviders={['doctor']} allowRoles={["doctor",'associate-doctor']}/>
            <PrivateLayout path="/prescriptions-list" exact component={ListOnlyPrescriptionPage} allowProviders={['doctor']} />
            <PrivateLayout path="/patients/:patientId/lims-referrals/:id" exact component={LimsReportDetailPage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor', 'doctor-nurse']} />
            <PrivateLayout path="/daisy-health-users" exact component={DaisyHealthUsersPage} allowProviders={['doctor']} />
            {/* Docter module route end */}

            {/* Non DH Doctor module routes */}
            <PrivateLayout path="/temp-doctor/patients/search" exact component={TempDoctorSearchPatientPage} allowProviders={['nondhdoctor']} />
            <PrivateLayout path="/temp-doctor/patients" exact component={TempDoctorListPatientPage} allowRoles={['nondhdoctor']} />
            <PrivateLayout path="/verified-report-list" exact component={TempDoctorVerifiedReportListPage} allowRoles={['nondhdoctor']} />
            <PrivateLayout path="/report-list/:patientId" exact component={TempDoctorReportListPage} allowRoles={['nondhdoctor']} />
            {/* Non DH Doctor module routes end */}


            { /* Pathology-Radioloy module routes start*/}
            <PrivateLayout path="/patient/pathology-tests" exact component={SearchPatientPathologyTest} allowProviders={['pathology']} />
            <PrivateLayout path="/queue" exact component={QueuePage} allowProviders={['pathology', 'radiology']} />
            <PrivateLayout path="/patient/radiology-tests" exact component={SearchPatientRadiologyTest} allowProviders={['radiology']} />
            <PrivateLayout path="/patient/pathology-test/:prescriptionId" exact component={AddToQueuePathologyPage} allowProviders={['pathology']} />
            <PrivateLayout path="/patient/radiology-test/:prescriptionId" exact component={AddToQueueRadiologyPage} allowProviders={['radiology']} />
            <PrivateLayout path="/pathology/orders" exact component={ListPathologyOrderPage} allowProviders={['pathology']} />
            <PrivateLayout path="/radiology/orders" exact component={ListRadiologyOrderPage} allowProviders={['radiology']} />
            <PrivateLayout path="/pathology/order/:orderId" exact component={ViewPathologyOrder} allowProviders={['pathology']} />
            <PrivateLayout path="/radiology/order/:orderId" exact component={ViewRadiologyOrder} allowProviders={['radiology']} />
            <PublicLayout path="/pathology/print/:prescriptionId" exact component={PathologyPrintPage} allowProviders={['pathology']} />
            <PublicLayout path="/radiology/print/:prescriptionId" exact component={RadiologyPrintPage} allowProviders={['radiology']} />
            <PrivateLayout path="/offline/patient/pathology-tests/:id" exact component={StartOfflinePathologyTest} allowProviders={['pathology']} />
            <PrivateLayout path="/offline/patient/radiology-tests/:id" exact component={StartOfflineRadiologyTest} allowProviders={['radiology']} />
            <PrivateLayout path="/pathology-test/prices" exact component={PathologyTestPriceListPage} allowProviders={['pathology']} allowRoles={['pathology']} />
            <PrivateLayout path="/pathology-lims-tests" exact component={PathologyLimsTestListPage} allowProviders={['pathology']} allowRoles={['pathology']} />
            <PrivateLayout path="/pathology/lims-tests/:id/:type" exact component={EditPathologyLimsTestPage} allowProviders={['pathology']} allowRoles={['pathology']} />
            <PrivateLayout path="/pathology-lims-tests/add" exact component={AddPathologyLimsTestPage} allowProviders={['pathology']} allowRoles={['pathology']} />
            <PrivateLayout path="/pathology-lims-tests/upload" exact component={UploadPathologyLimsTestPage} allowProviders={['pathology']} allowRoles={['pathology']} />
            <PrivateLayout path="/radiology-test/prices" exact component={RadiologyTestPriceListPage} allowProviders={['radiology']} allowRoles={['radiology']} />
            <PrivateLayout path="/pathology/lims-test/prices" exact component={ListLimsTestPricePage} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-manager']} />
            <PrivateLayout path="/pathology/lims-test/prices/upload" exact component={UploadLimsTestPricePage} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-manager']} />
            <PrivateLayout path="/pathology/lims-test/fees" exact component={ListTestFeesPage} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-manager', 'pathology-receptionist']} />
            { /* Pathology-Radioloy module routes end*/}
            <PrivateLayout path="/referral/:id" exact component={LabReportDateEntryClerk} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'data-entry-clerk', 'pathology-receptionist', 'pathology-phlebotomist','pathology-med-tech', 'pathology-lab-tech']} />
            <PrivateLayout path="/draft-referral/:id" exact component={DraftLabReport} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'data-entry-clerk', 'pathology-receptionist', 'pathology-phlebotomist','pathology-med-tech', 'pathology-lab-tech']} />
            <PrivateLayout path="/lab-report/:id" exact component={LabReportMedTech} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-manager', 'data-entry-clerk', 'pathology-med-tech', 'pathology-lab-tech', 'pathology-phlebotomist', 'pathology-receptionist', 'pathologist']} />
            <PrivateLayout path="/completed-patient-report/:id" exact component={CompletedReportDetailPage} allowProviders={['pathology']} allowRoles={['pathology', 'pathology-manager', 'data-entry-clerk', 'pathology-receptionist', 'pathology-med-tech', 'pathology-lab-tech', 'pathology-phlebotomist', 'pathologist']} />
            <PrivateLayout path="/pending-patient-report" exact component={PendingPatientReport} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-manager', 'pathology-med-tech', 'pathology-lab-tech', 'data-entry-clerk', 'pathology-phlebotomist', 'pathology-receptionist','pathologist']} />
            <PrivateLayout path="/processing-patient-report" exact component={ProcessingPatientReport} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-manager', 'pathology-med-tech', 'data-entry-clerk', 'pathology-phlebotomist', 'pathology-receptionist','pathologist']} />
            <PrivateLayout path="/completed-patient-report" exact component={CompletedPatientReport} allowProviders={['pathology']} allowRoles={['pathology', 'pathology-manager', 'data-entry-clerk', 'pathology-receptionist', 'pathology-med-tech', 'pathology-phlebotomist', 'pathology-lab-tech', 'pathologist']} />
            <PrivateLayout path="/printed-patient-report" exact component={PrintedPatientReport} allowProviders={['pathology']} allowRoles={['pathology', 'pathology-receptionist', 'pathology-phlebotomist', 'pathology-med-tech', 'pathology-lab-tech', 'pathologist']} />
            <PrivateLayout path="/patient-referral-list" exact component={PatientReferralList} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-receptionist', 'pathology-phlebotomist', 'pathology-med-tech', 'pathology-lab-tech', 'data-entry-clerk']} />
            <PrivateLayout path="/draft-referral" exact component={DraftPatientReferralList} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-receptionist', 'pathology-phlebotomist', 'pathology-med-tech', 'pathology-lab-tech', 'data-entry-clerk']} />
            <PrivateLayout path="/add-referral/:id" exact component={AddReferralForm} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-receptionist', 'pathology-phlebotomist']} />
            <PrivateLayout path="/test-done-report" exact component={ListTestDoneReport} allowProviders={['pathology']} allowRoles={['pathology', 'pathologist', 'pathology-med-tech', 'pathology-lab-tech']} />

            {/* pharmacy module route start */}
            <PrivateLayout path="/pharmacy/prescriptions/:id/edit" exact component={PharmacyEditPrescriptionPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/prescription/orders/list" exact component={ListPrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/pharmacy/prescription/:prescriptionid/view" exact component={ViewPrescription} allowProviders={['pharmacy']} />
            <PrivateLayout path="/pharmacy/technician/prescription/list" exact component={TechnicianPrescriptionList} allowProviders={['pharmacy']} />
            <PrivateLayout path="/pharmacy/submit/order/:prescriptionId" exact component={EditPrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/prescription/orders/queue" exact component={ListPrescriptionQueuePage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/pharmacy/prescriptions/search" exact component={SearchPrescriptionPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/prescriptions/:prescriptionId/orders/create" exact component={AddPrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/prescriptions/:prescriptionId/orders/summary" exact component={SummaryPrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/technician/prescriptions/:prescriptionId/orders/create" exact component={TechnicianPrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/operate/order/:orderId" exact component={OperatePrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/prescription/order/:id" exact component={ViewPrescriptionOrderPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/drugs/prices" exact component={DrugPriceListPage} allowProviders={['pharmacy']} allowRoles={['pharmacy', 'pharmacy-manager']} />
            <PrivateLayout path="/orders/returns" exact component={ReturnedOrderList} allowProviders={['pharmacy']} />
            <PrivateLayout path="/modified-prescriptions/pending-signature" exact component={SignaturePendingModifiedPrescriptions} allowProviders={['pharmacy']} />
            <PrivateLayout path="/orders/pending-signature" exact component={SignaturePendingOrders} allowProviders={['pharmacy']} allowRoles={['pharmacist']} />
            <PrivateLayout path="/pharmacy/patients/:patientId/prescriptions" exact component={AddPharmacyPrescriptionPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/pharmacy/draft/prescriptions" exact component={ListEmergencyPrescriptionPage} allowProviders={['pharmacy']} allowRoles={['pharmacist']} />
            <PrivateLayout path="/pharmacy/prescriptions" exact component={PharmacyPrescriptionsPage} allowProviders={['pharmacy']} />
            <PrivateLayout path="/reminders" exact component={OrderReminderList} allowProviders={['pharmacy']} />
            {/* pharmacy module route end */}

            {/* ambulance module route start */}
            <PrivateLayout path="/ambulance/patients/search" exact component={AmbulanceSearchPatientPage} allowProviders={['ambulance']} />
            <PrivateLayout path="/ambulance/records/patients/:patientId" exact component={AddAmbulanceRecordPage} allowProviders={['ambulance']} />
            <PrivateLayout path="/ambulance/records" exact component={ListAmbulanceRecordPage} allowProviders={['ambulance']} />
            <PrivateLayout path="/ambulance/records/:recordId" exact component={EditAmbulanceRecordPage} allowProviders={['ambulance']} />
            <PrivateLayout path="/ambulance/records/:recordId/view" exact component={ViewAmbulanceRecordPage} allowProviders={['ambulance']} />
            {/* ambulance module route end */}
            {/* hospital module route start */}
            <PrivateLayout path="/hospital/patients/:status" exact component={HospitalListPatientPage} allowProviders={['hospital']} />
            <PrivateLayout path="/hospital/records/:recordId" exact component={ViewPatientRecordPage} allowProviders={['hospital']} />
            <PrivateLayout path="/hospital/records/:recordId/prescription/:prescriptionId" exact component={AddRecordPrescriptionPage} allowProviders={['hospital']} />
            <PrivateLayout path="/hospital/:recordId/:prescriptionId/summary" exact component={SummaryRecordPrescriptionPage} allowProviders={['hospital']} />
            <PrivateLayout path="/hospital/patients/:patientId" exact component={ShowPatientPage} allowProviders={['hospital']} />
            {/* hospital module route end */}

            {/* covid module route start */}
            <PrivateLayout path="/covid-centers/patients/:patientId" exact component={CovidPatientsPage} allowProviders={['covid-center']} />
            <PrivateLayout path="/patients/:patientId/reports/:reportId" exact component={CovidReportGeneratePage} allowProviders={['covid-center']} />
            <PrivateLayout path="/covid-centers/pending-reports" exact component={CovidPendingReportPage} allowProviders={['covid-center']} />
            <PrivateLayout path="/covid-centers/completed-reports" exact component={CovidCompletedReportPage} allowProviders={['covid-center']} />
            <PrivateLayout path="/covid-centers/uploaded-reports" exact component={CovidUploadedReportPage} allowProviders={['covid-center']} />
            <PrivateLayout path="/covid-centers/payments" exact component={PaymentDetailsPage} allowProviders={['covid-center']} />
            <PrivateLayout path="/covid-centers/patients" exact component={CovidTestPatientsPage} allowProviders={['covid-center']} />
            <PrivateLayout path="/patient-queue" exact component={QueuePage} allowProviders={['covid-center']} />
            <PrivateLayout path="/patient-details" exact component={CovidPatientDetailsPage} allowRoles={['covid-vendor']} />
            {/* covid module route end */}
            {/** Airline Module */}
            <PrivateLayout path="/authority-access/patients/search" exact component={AirlineSearchPatientPage} />
            <PrivateLayout path="/authority-access/patients/:patientId" exact component={AirlineViewPatientPage} />
            <PrivateLayout path="/authority-access/past-scan-records" exact component={AirlinePastScanRecordPage} />
            {/** Airline Module */}
            <PrivateLayout path="/assign-card" exact component={AssignCardPage} />
            <PrivateLayout path="/offline/prescriptions" exact component={CreateOfflinePrescritpion} />
            <PrivateLayout path="/account-expired" exact component={TrialAccountExpirePage} />
            <PrivateLayout path="/offline/prescriptions/:id/view" exact component={ViewOfflinePrescription} />
            <PrivateLayout path="/offline/prescriptions/:id/orders/create" exact component={CreateOfflineOrders} />
            <PrivateLayout path="/service-providers/accounts" exact component={UserAccounts} allowProviders={['radiology', 'pathology', 'doctor', 'pharmacy']} allowRoles={['pharmacy', 'pathology', 'radiology', 'doctor', 'associate-doctor', 'medical-practice-manager']} />
            {/* <PrivateLayout path="/service-providers/:id/accounts" exact component={UserAccountInfo} /> */}
            <PrivateLayout path="/context-help" exact component={ContextHelp} />


            <PrivateLayout path="/urgent-care" exact component={ListUrgentCarePage} allowProviders={['doctor']} />

            <PrivateLayout path="/procedure" exact component={ListProcedurePage} allowProviders={['doctor']} allowRoles={["doctor", "doctor-nurse", "medical-practice-manager", hasRole(selected_user_profile, ["associate-doctor"]) && selected_user_profile.is_procedure_listed ? "associate-doctor" : ""]} />

            <PrivateLayout path="/data-management" component={DataManagement} allowRoles={['covid-center-owner']} />

            <PrivateLayout path="/patient-confirm-edit" exact component={ListPatientConfirmEditPage} allowProviders={['doctor']} allowRoles={['doctor', 'associate-doctor','medical-practice-manager']} />
            <PrivateLayout path="/patient-confirm-edit/:patientId/:editProfileId" exact component={EditPatientConfirmPage} allowRoles={['doctor', 'associate-doctor', 'medical-practice-manager']} />
            <PrivateLayout path="/qrcode" exact component={QrCode} allowProviders={['doctor', 'covid-center', 'pathology']} />

            <PrivateLayout path="/patient/pre-registered" exact component={ListPreRegisteredPatientPage} allowProviders={['doctor', 'covid-center', 'pathology']} />

            <PrivateLayout path="/unauthorized" exact component={PermissionDeniedPage} />
            <PrivateLayout component={NotFoundPage} />
          </Switch></Suspense>}
    </ConnectedRouter>
  );
}

const mapStateToProps = state => ({
  checked: state.session.checked,
  user: state.session.user,
  selected_user_profile: state.selected_user_profile
})

export default connect(mapStateToProps)(App);
