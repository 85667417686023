import React from 'react';
import DataGrid from '../DataGrid';
import * as Helper from "../../utils/helpers";
import Notification from "../../utils/notification";
import PatientMedicalReportService from '../../services/patientMedicalReportService';

class PatientSelfUploadedReport extends React.Component {

    columns = () => {
        return [
            {
                dataField: "report_name",
                text: "Report Name",
                sort: true,
                formatter: this.dateTimeFormat,
            },
            {
                dataField: "document_name",
                text: "File Name",
                sort: false,
            },
            {
                dataField: "created_at",
                text: "Date",
                sort: false,
                formatter: this.dateFormatter,
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: this.actionFormatter,
            },
        ];
    }

    dateFormatter = (cell, row) => {
        return Helper.getDateFormat(cell);
    };

    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>

                <button className="btn btn-outline-primary" style={{ minWidth: "50px", padding: "5px 0" }} onClick={e => this.downloadDocument(row.document_id)} title="View">
                    View
                </button>
            </React.Fragment>
        );
    };

    downloadDocument = (document_id) => {
        PatientMedicalReportService.getDocumentDownloadUrl(document_id)
            .then(resp => {
                let data = resp.data;
                window.open(data.download_url);
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.props.uploadedMedicalReports && <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={this.props.uploadedMedicalReports.data}
                        meta={this.props.uploadedMedicalReports.meta}
                        noDataIndication="No records found"
                        handleTableChange={this.props.handlePatientsUploadedMedicalReportTableChange}
                    />
                </div>}
            </React.Fragment>
        )
    }
}
export default PatientSelfUploadedReport