import axios from 'axios';
import { apiRoute, getApiHeader, createPaginateQueryString } from '../utils/helpers';

class PatientMedicalReportService {

    static getselfUploadedMedicalReport(patientId, { page, sizePerPage, sortBy, searchText, filter }) {
        const requestOptions = {
            headers: getApiHeader(),
            params: createPaginateQueryString({ page, sizePerPage, sortBy, searchText, filter })
        };

        return axios.get(apiRoute(`/v1/patients/${patientId}/self-medical-report`), requestOptions);
    }

    static getDocumentDownloadUrl(documentId) {
        const requestOptions = {
            headers: getApiHeader(),
        };

        return axios.get(apiRoute('/v1/documents/' + documentId + '/download'), requestOptions);
    }
}

export default PatientMedicalReportService;
