import React from 'react';
import DataGrid from './DataGrid';
import UploadRadiologyReportModal from './UploadRadiologyReportModal';
import SimpleReactValidator from 'simple-react-validator';

class PatientRadiologyTestTab extends React.Component {
    state = {
        show_upload_document_modal: false,
        tags: {
            test_name: "",
            date: "",
        },
        filter: {
            test_name: "",
            date: "",
        }
    }
    validator = new SimpleReactValidator();
    handleReportUploadModal = () => {
        this.setState({ show_upload_document_modal: !this.state.show_upload_document_modal });
    }
    handleChange = (e) => {
        const filter = { ...this.state.filter, [e.target.name]: e.target.value || "" };
        this.setState({ filter });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const { test_name, date } = this.state.filter;

        this.setState({ tags: { test_name, date } });
        window['searchToggle']();
        this.props.handleRadiologyTableChange("search", {
            page: 1,
            test_name,
            date
        });
    }
    resetSearch = () => {
        const resetFilter = { test_name: "", date: "" };
        this.setState({ filter: resetFilter, tags: resetFilter });
        window['searchToggle']();
        this.props.handleRadiologyTableChange("reset", resetFilter);
    }

    removeFilter = (e) => {
        const tag = e.target.dataset.tag;
        const filter = { ...this.state.filter, [tag]: "" };
        const tags = { ...this.state.tags, [tag]: "" };

        this.setState({ filter, tags });
        this.props.handleRadiologyTableChange("filter", { filter });
    }
    render() {
        let { documents, userRole} = this.props;
        let tags = this.state.tags;
        return (
            <React.Fragment>
                {userRole !== 'junior-doctor' && (
                    <button
                        type="button"
                        className="btn btn-primary float-right mt-3 mb-3"
                        onClick={this.handleReportUploadModal}
                    >
                        Upload Report
                    </button>
                )}
                <div className="search-wrapper mt-3">
                    <label>Search</label>
                    <div className="search-box clearfix">
                        {Object.keys(tags).map((tag) => (
                            tags[tag] ? (
                                <span key={tag} className="search-list">
                                    {tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter}> x </span>
                                </span>
                            ) : null
                        ))}
                        <div className="search-container">
                            <form onSubmit={this.handleSubmit}>
                                <ul>
                                    <li>
                                        <span>Test Name</span>
                                        <input type="text" value={this.state.filter.test_name} onChange={this.handleChange} name="test_name" />
                                    </li>
                                    <li>
                                        <span>Date</span>
                                        <input type="date" value={this.state.filter.date} onChange={this.handleChange} name="date" />
                                    </li>
                                    <li>
                                        <button type="button" onClick={this.resetSearch} className="btn btn-secondary">Reset</button>
                                        <button type="submit" className="btn btn-primary">Search</button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
                {this.props.documents && <div className="content-wrapper">
                    <DataGrid
                        columns={this.props.radiologyColumns}
                        data={this.props.documents.data}
                        meta={this.props.documents.meta}
                        noDataIndication="No records found!"
                        handleTableChange={this.props.handleRadiologyTableChange}
                    />
                </div>}
                {this.state.show_upload_document_modal && <UploadRadiologyReportModal
                    show={this.state.show_upload_document_modal}
                    patient={this.props.patient}
                    handleModal={this.handleReportUploadModal}
                    refreshList={this.props.refreshRadiologyList} />}
            </React.Fragment>
        )
    }
}
export default PatientRadiologyTestTab