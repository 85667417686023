import React from 'react';
import { connect } from 'react-redux';
import * as PatientAction from '../../actions/patientAction';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import Alert from '../../components/Alert';
import CreatableSelect from 'react-select/creatable';
import * as Config from '../../config';
import _ from 'lodash';
import moment from 'moment';
import PatientService from '../../services/patientService';
import CommunityService from '../../services/communityService';
import Notification from '../../utils/notification';
import { personNameRegex } from '../../utils/helpers';
import PatientConfirmEditService from '../../services/patientConfirmEditService';
import PatientProfileUpdateModal from '../../components/PatientProfileUpdateModal';
import CountryService from '../../services/CountryService';
import Select from 'react-select';

class EditPatientConfirmPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            old_patientId: "",
            old_title: '',
            old_first_name: "",
            old_middle_name: "",
            old_last_name: "",
            old_father_name: "",
            old_mother_name: "",
            old_maiden_name: "",
            old_email: "",
            old_contact_number: "",
            old_alt_contact_number: "",
            old_place_of_birth: "",
            old_blood_group: "",
            old_gender: "",
            old_card_number: "",
            old_healthcard_number: "",
            old_date_of_birth: "",
            old_address_line_1: "",
            old_address_line_2: "",
            old_address_line_3: "",
            old_city: "",
            old_pincode: "",
            old_parish: "",
            old_insurance_type: [],
            old_is_jadep: false,
            old_is_nhf: false,
            old_is_goj: false,
            old_is_other: false,
            old_jadep_number: null,
            old_nhf_number: null,
            old_goj_number: null,
            old_is_no: false,
            old_is_private_sagicore: false,
            old_is_private_medicus: false,
            old_is_private_canopy: false,
            old_flag: "null",
            old_isEditing: false,
            old_isJadepDisabled: true,
            old_allergy: [],
            old_comorbidities: [],
            old_disabled: true,
            old_height: '',
            old_kin_name: '',
            old_kin_number: '',
            old_kin_relation: '',
            old_nearest_police_station: '',
            old_sagicore: '',
            old_medicus: '',
            old_canopy: '',
            old_occupation: '',
            old_private_insurance_sagicore_number: '',
            old_private_insurance_medicus_number: '',
            old_private_insurance_canopy_number: '',
            old_profile_pic_url: '',
            old_disableSubmit: false,

            new_title: '',
            new_first_name: "",
            new_middle_name: "",
            new_last_name: "",
            new_father_name: "",
            new_mother_name: "",
            new_maiden_name: "",
            new_email: "",
            new_contact_number: "",
            new_alt_contact_number: "",
            new_place_of_birth: "",
            new_blood_group: "",
            new_gender: "",
            new_card_number: "",
            new_healthcard_number: "",
            new_date_of_birth: "",
            new_address_line_1: "",
            new_address_line_2: "",
            new_address_line_3: "",
            new_city: "",
            new_pincode: "",
            new_parish: "",
            new_insurance_type: [],
            new_is_jadep: false,
            new_is_nhf: false,
            new_is_goj: false,
            new_is_other: false,
            new_jadep_number: null,
            new_nhf_number: null,
            new_goj_number: null,
            new_is_no: false,
            new_is_private_sagicore: false,
            new_is_private_medicus: false,
            new_is_private_canopy: false,
            new_flag: "null",
            new_isEditing: false,
            new_isJadepDisabled: true,
            new_allergy: [],
            new_comorbidities: [],
            new_disabled: true,
            new_height: '',
            new_kin_name: '',
            new_kin_number: '',
            new_kin_relation: '',
            new_nearest_police_station: '',
            new_sagicore: '',
            new_medicus: '',
            new_canopy: '',
            new_occupation: '',
            new_private_insurance_sagicore_number: '',
            new_private_insurance_medicus_number: '',
            new_private_insurance_canopy_number: '',
            new_profilePicId: '',
            disableSubmit: false,
            new_profile_pic_url: '',
            show_model: false,
            communities: [],
            selectedParisCommunity: [],
            old_country: null,
            new_country: null,
            countries: [],
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInsuranceTypeChange = this.handleInsuranceTypeChange.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.validator = new SimpleReactValidator({
            validators: {
                insurance_type: {
                    message: 'Select atleast one insurance type or select No',
                    rule: (val) => {
                        return val.length > 0;
                    },
                    required: true
                }
            }
        });
        this.getCommunity();
        this.setSelectedParisCommunity(this.state.new_parish)
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const editProfileId = params.editProfileId;
        this.setPatientProfileNewData(editProfileId);
        this.loadCountries();
    }

    getCommunity = () => {
        CommunityService.listAll().then(resp => {
            this.setState({
                communities: resp.data.data,
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    loadCountries = () => {
        CountryService.listAll().then(resp => {
            let countries = resp.data.map((items) => {
                return { 'label': items.name, 'value': items.name };
            })
            this.setState({
                countries
            });
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    setCountry = (country) => {
        this.setState({ new_country: country ? country.value : '' }, () => {
            this.setState({
                new_parish: "",
                new_city: "",
                new_pincode: ""
            });
        });
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = _.filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ new_city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    async setPatientProfileNewData(id) {
        const { data } = await this.fetchPatientRequestData(id);
        const currentDate = moment().format('YYYY-MM-DD');
        const jadepEligibleDate = moment(data.new_data.new_date_of_birth).add(60, 'y').format('YYYY-MM-DD');
        this.setState(state => ({
            new_title: data.new_data.name_title,
            new_first_name: data.new_data.first_name,
            new_middle_name: data.new_data.middle_name,
            new_last_name: data.new_data.last_name,
            new_father_name: data.new_data.father_name,
            new_mother_name: data.new_data.mother_name,
            new_maiden_name: data.new_data.maiden_name,
            new_email: data.new_data.email,
            new_contact_number: data.new_data.contact_number,
            new_alt_contact_number: data.new_data.alt_contact_number,
            new_place_of_birth: data.new_data.place_of_birth,
            new_blood_group: data.new_data.blood_group,
            new_gender: data.new_data.gender,
            new_card_number: data.new_data.name_title,
            new_healthcard_number: data.new_data.healthcard_number,
            new_date_of_birth: data.new_data.date_of_birth,
            new_address_line_1: data.new_data.address.address_line_1,
            new_address_line_2: data.new_data.address.address_line_2,
            new_address_line_3: data.new_data.address.address_line_3,
            new_city: data.new_data.address.city,
            new_country: data.new_data.address.country ? data.new_data.address.country: '',
            new_pincode: data.new_data.address.pincode,
            new_parish: data.new_data.address.parish,
            new_insurance_type: data.new_data.insurance_type ? data.new_data.insurance_type : [],
            new_is_jadep: data.new_data.insurance_type && data.new_data.insurance_type.includes('jadep') ? true : false,
            new_is_nhf: data.new_data.insurance_type && data.new_data.insurance_type.includes('nhf') ? true : false,
            new_is_goj: data.new_data.insurance_type && data.new_data.insurance_type.includes('goj') ? true : false,
            new_is_other: data.new_data.insurance_type && data.new_data.insurance_type.includes('other') ? true : false,
            new_jadep_number: data.new_data.jadep_number ? data.new_data.jadep_number : null,
            new_nhf_number: data.new_data.nhf_number ? data.new_data.nhf_number : null,
            new_goj_number: data.new_data.goj_number ? data.new_data.goj_number : null,
            new_is_no: data.new_data.insurance_type && data.new_data.insurance_type.includes('no') ? true : false,
            new_is_private_sagicore: data.new_data.insurance_type && data.new_data.insurance_type.includes('private-insurance-sagicore') ? true : false,
            new_is_private_medicus: data.new_data.insurance_type && data.new_data.insurance_type.includes('private-insurance-medicus') ? true : false,
            new_is_private_canopy: data.new_data.insurance_type && data.new_data.insurance_type.includes('private-insurance-canopy') ? true : false,
            new_flag: "null",
            new_isEditing: true,
            new_isJadepDisabled: (jadepEligibleDate <= currentDate) ? false : true,
            new_allergy: data.new_data.allergy ? data.new_data.allergy : [],
            new_comorbidities: data.new_data.comorbidities ? data.new_data.comorbidities : [],
            new_height: data.new_data.height ? data.new_data.height : null,
            new_kin_name: data.new_data.kin_name ? data.new_data.kin_name : null,
            new_kin_number: data.new_data.kin_number ? data.new_data.kin_number : null,
            new_kin_relation: data.new_data.kin_relation ? data.new_data.kin_relation : null,
            new_nearest_police_station: data.new_data.nearest_police_station ? data.new_data.nearest_police_station : null,
            new_occupation: data.new_data.occupation,
            new_private_insurance_sagicore_number: data.new_data.sagicore_number,
            new_private_insurance_medicus_number: data.new_data.medicus_number,
            new_private_insurance_canopy_number: data.new_data.canopy_number,
            new_profilePicId: data.new_data.profile_picture_id,
            new_profile_pic_url: data.new_data.profile_image ? data.new_data.profile_image.image_url : null,

            old_title: data.old_data.name_title,
            old_first_name: data.old_data.first_name,
            old_middle_name: data.old_data.middle_name,
            old_last_name: data.old_data.last_name,
            old_father_name: data.old_data.father_name,
            old_mother_name: data.old_data.mother_name,
            old_maiden_name: data.old_data.maiden_name,
            old_email: data.old_data.email,
            old_contact_number: data.old_data.contact_number,
            old_alt_contact_number: data.old_data.alt_contact_number,
            old_place_of_birth: data.old_data.place_of_birth,
            old_blood_group: data.old_data.blood_group,
            old_gender: data.old_data.gender,
            old_card_number: data.old_data.name_title,
            old_healthcard_number: data.old_data.healthcard_number,
            old_date_of_birth: data.old_data.date_of_birth,
            old_address_line_1: data.old_data.address.address_line_1,
            old_address_line_2: data.old_data.address.address_line_2,
            old_address_line_3: data.old_data.address.address_line_3,
            old_city: data.old_data.address.city,
            old_country: data.old_data.address.country ? data.old_data.address.country:'',
            old_pincode: data.old_data.address.pincode,
            old_parish: data.old_data.address.parish,
            old_insurance_type: data.old_data.insurance_type ? data.old_data.insurance_type : [],
            old_is_jadep: data.old_data.insurance_type && data.old_data.insurance_type.includes('jadep') ? true : false,
            old_is_nhf: data.old_data.insurance_type && data.old_data.insurance_type.includes('nhf') ? true : false,
            old_is_goj: data.old_data.insurance_type && data.old_data.insurance_type.includes('goj') ? true : false,
            old_is_other: data.old_data.insurance_type && data.old_data.insurance_type.includes('other') ? true : false,
            old_jadep_number: data.old_data.jadep_number ? data.old_data.jadep_number : null,
            old_nhf_number: data.old_data.nhf_number ? data.old_data.nhf_number : null,
            old_goj_number: data.old_data.goj_number ? data.old_data.goj_number : null,
            old_is_no: data.old_data.insurance_type && data.old_data.insurance_type.includes('no') ? true : false,
            old_is_private_sagicore: data.old_data.insurance_type && data.old_data.insurance_type.includes('private-insurance-sagicore') ? true : false,
            old_is_private_medicus: data.old_data.insurance_type && data.old_data.insurance_type.includes('private-insurance-medicus') ? true : false,
            old_is_private_canopy: data.old_data.insurance_type && data.old_data.insurance_type.includes('private-insurance-canopy') ? true : false,
            old_flag: "null",
            old_isEditing: true,
            old_isJadepDisabled: (jadepEligibleDate <= currentDate) ? false : true,
            old_allergy: data.old_data.allergy ? data.old_data.allergy : [],
            old_comorbidities: data.old_data.comorbidities ? data.old_data.comorbidities : [],
            old_height: data.old_data.height ? data.old_data.height : null,
            old_kin_name: data.old_data.kin_name ? data.old_data.kin_name : null,
            old_kin_number: data.old_data.kin_number ? data.old_data.kin_number : null,
            old_kin_relation: data.old_data.kin_relation ? data.old_data.kin_relation : null,
            old_nearest_police_station: data.old_data.nearest_police_station ? data.old_data.nearest_police_station : null,
            old_occupation: data.old_data.occupation,
            old_private_insurance_sagicore_number: data.old_data.sagicore_number,
            old_private_insurance_medicus_number: data.old_data.medicus_number,
            old_private_insurance_canopy_number: data.old_data.canopy_number,
            old_profilePicId: data.old_data.profile_picture_id,
            old_profile_pic_url: data.old_data.profile_image ? data.old_data.profile_image.thumbnail_url : null,
        }))
    }

    async fetchPatientRequestData(id) {
        try {
            return await PatientConfirmEditService.getById(id);
        } catch (error) {
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data)
            }
        }
    }

    onChange(e) {
        const name = e.target.name
        const value = e.target.value

        if (name === 'new_date_of_birth') {
            const patientAge = moment().diff(value, 'years');
            if (patientAge >= 60) {
                this.setState({ [name]: value, new_isJadepDisabled: false });
            } else {
                this.setState({
                    [name]: value,
                    new_isJadepDisabled: true,
                    new_jadep_number: '',
                    new_is_jadep: false
                });
            }
            return true;
        }

        if (name === 'new_parish') {
            this.setSelectedParisCommunity(value)
            this.setCommunity(null)
        }

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
    }

    handlePatientProfileUpdate = (evt, type = null) => {
        // evt.preventDefault();

        if (type === 'close') {
            this.setState({
                show_model: false
            })
        }

        let data = {
            name_title: this.state.new_title,
            first_name: this.state.new_first_name,
            middle_name: this.state.new_middle_name,
            last_name: this.state.new_last_name,
            father_name: this.state.new_father_name,
            mother_name: this.state.new_mother_name,
            maiden_name: this.state.new_maiden_name,
            date_of_birth: this.state.new_date_of_birth,
            gender: this.state.new_gender,
            email: this.state.new_email,
            contact_number: this.state.new_contact_number,
            alt_contact_number: this.state.new_alt_contact_number,
            place_of_birth: this.state.new_place_of_birth,
            blood_group: this.state.new_blood_group,
            healthcard_number: this.state.new_healthcard_number,
            insurance_type: this.state.new_insurance_type,
            jadep_number: this.state.new_new_is_jadep ? this.state.new_new_jadep_number : '',
            nhf_number: this.state.new_is_nhf ? this.state.new_nhf_number : '',
            goj_number: this.state.new_is_goj ? this.state.new_goj_number : '',
            address: {
                address_line_1: this.state.new_address_line_1,
                address_line_2: this.state.new_address_line_2,
                address_line_3: this.state.new_address_line_3,
                city: this.state.new_city,
                country: this.state.new_country,
                pincode: this.state.new_pincode,
                parish: this.state.new_parish
            },
            allergy: this.state.new_allergy,
            comorbidities: this.state.new_comorbidities,
            height: this.state.new_height,
            kin_name: this.state.new_kin_name ? this.state.new_kin_name : '',
            kin_number: this.state.new_kin_number ? this.state.new_kin_number : '',
            kin_relation: this.state.new_kin_relation ? this.state.new_kin_relation : '',
            nearest_police_station: this.state.new_nearest_police_station ? this.state.new_nearest_police_station : '',
            sagicore: this.state.new_sagicore,
            medicus: this.state.new_medicus,
            canopy: this.state.new_canopy,
            occupation: this.state.new_occupation ? this.state.new_occupation : '',
            sagicore_number: this.state.new_private_insurance_sagicore_number ? this.state.new_private_insurance_sagicore_number : '',
            medicus_number: this.state.new_private_insurance_medicus_number ? this.state.new_private_insurance_medicus_number : '',
            canopy_number: this.state.new_private_insurance_canopy_number ? this.state.new_private_insurance_canopy_number : '',
            profile_picture_id: this.state.new_profilePicId ? this.state.new_profilePicId : '',
            profile_edit_queue_id: this.props.match.params.editProfileId,
            status_type: 'completed',
            updatedby: this.props.selected_user_profile.user_id
        };

        if (type === 'save') {
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate();
                return this.setState({
                    show_model: false
                })
            }
            PatientService.updatePatientProfile(data, this.props.match.params.patientId, this.props.history).then(resp => {
                Notification.show('success', { message: 'Patient Profile Updated Successfully' });
                this.setState({
                    show_model: false
                })

                setTimeout(() => {
                    Notification.clearAll();
                    this.props.history.push("/patients/" + this.props.match.params.patientId);
                }, 2000);
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
        if (type === 'cancel') {
            data.status_type = 'reject';
            PatientService.updatePatientProfile(data, this.props.match.params.patientId, this.props.history).then(resp => {
                Notification.show('error', { message: 'Patient Profile Edit Rejected' });
                this.setState({
                    show_model: false
                })

                setTimeout(() => {
                    Notification.clearAll();
                    this.props.history.push("/patients/" + this.props.match.params.patientId);
                }, 2000);
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
    }

    handleInsuranceTypeChange(e) {
        let insurance_type = this.state.new_insurance_type;

        const { new_is_no, new_is_private_sagicore, new_is_private_medicus, new_is_private_canopy, new_is_other } = this.state;

        if (e.target.value === "no") {
            insurance_type = ["no"];
            this.setState({
                new_is_no: !new_is_no,
                new_is_jadep: e.target.unchecked,
                new_is_nhf: e.target.unchecked,
                new_is_goj: e.target.unchecked,
                new_is_private_sagicore: e.target.unchecked,
                new_is_private_medicus: e.target.unchecked,
                new_is_private_canopy: e.target.unchecked,
                new_is_other: e.target.unchecked,
                new_jadep_number: "",
                new_nhf_number: "",
                new_goj_number: "",
                new_private_insurance_sagicore_number: "",
                new_private_insurance_medicus_number: "",
                new_private_insurance_canopy_number: "",
            });
        } else {
            _.pull(insurance_type, "no");
        }

        if (e.target.checked) {
            insurance_type.push(e.target.value);
        } else {
            if (e.target.value === 'jadep') {
                this.setState({
                    new_jadep_number: null
                });
            }
            if (e.target.value === 'nhf') {
                this.setState({
                    new_nhf_number: null
                });
            }
            if (e.target.value === 'goj') {
                this.setState({
                    new_goj_number: null
                });
            }

            if (e.target.value === 'private-insurance-sagicore') {
                this.setState({
                    new_private_insurance_sagicore_number: ''
                });
            }
            if (e.target.value === 'private-insurance-medicus') {
                this.setState({
                    new_private_insurance_medicus_number: ''
                });
            }
            if (e.target.value === 'private-insurance-canopy') {
                this.setState({
                    new_private_insurance_canopy_number: ''
                });
            }

            _.pull(insurance_type, e.target.value);
        }

        if (e.target.value === 'other') {
            this.setState({
                new_is_other: !new_is_other,
                new_is_no: false
            });
        }

        if (e.target.value === 'jadep') {
            this.setState({
                new_is_no: false,
                new_is_jadep: e.target.checked
            });
        }
        if (e.target.value === 'nhf') {
            this.setState({
                new_is_no: false,
                new_is_nhf: e.target.checked
            });
        }
        if (e.target.value === 'goj') {
            this.setState({
                new_is_no: false,
                new_is_goj: e.target.checked
            });
        }
        if (e.target.value === 'private-insurance-sagicore') {
            this.setState({
                new_is_private_sagicore: !new_is_private_sagicore,
                new_is_no: false
            });
        }

        if (e.target.value === 'private-insurance-medicus') {
            this.setState({
                new_is_private_medicus: !new_is_private_medicus,
                new_is_no: false
            });
        }

        if (e.target.value === 'private-insurance-canopy') {
            this.setState({
                new_is_private_canopy: !new_is_private_canopy,
                new_is_no: false
            });
        }
        if (e.target.value === 'no') {
            insurance_type = ['no'];
            this.setState({
                new_is_no: !new_is_no,
                new_is_jadep: e.target.unchecked,
                new_is_nhf: e.target.unchecked,
                new_is_goj: e.target.unchecked,
                new_is_private_sagicore: e.target.unchecked,
                new_is_private_medicus: e.target.unchecked,
                new_is_private_canopy: e.target.unchecked,
                new_jadep_number: null,
                new_nhf_number: null,
                new_goj_number: null,
                new_private_insurance_sagicore_number: "",
                new_private_insurance_medicus_number: "",
                new_private_insurance_canopy_number: "",
            });
        } else {
            _.pull(insurance_type, 'no');
        }

        this.setState({ insurance_type: insurance_type });
    }

    handleTagsChange(e) {
        let value = e.target.value ? (e.target.value).split(",") : [];
        this.setState({ [e.target.name]: value });
    }

    onFileChange = event => {

        let file = event.target.files[0];
        let fileSize = file.size / 1024;
        if (fileSize > 5120) {
            Notification.show('error', { "message": "The file may not be greater than 5 MB." });
            return false;
        }
        this.setState({
            disableSubmit: true
        });
        const formData = new FormData();
        formData.append('file', file);
        PatientService.uploadProfilePicture(formData).then(resp => {
            this.setState({
                new_profilePicId: resp.data.image_id,
                new_profile_pic_url: resp.data.thumbnail_url,
                disableSubmit: false
            })
        }).catch(error => {
            this.setState({
                disableSubmit: false
            });
            Notification.show('error', error.response.data)
        })
    };

    deleteProfile = () => {
        this.setState({
            disableSubmit: true
        });
        PatientService.deleteProfilePicture(this.state.new_profilePicId).then(resp => {
            this.setState({
                new_profilePicId: '',
                profilePicUrl: '',
                disableSubmit: false,
                new_profile_pic_url: ''
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    checkOldAndNewPatientData(old_data, new_data) {
        if (old_data === null && new_data && new_data.length > 0) {
            return (
                <span><strong>New: </strong>{new_data}</span>
            )
        } else if (old_data !== new_data && old_data && old_data.length > 0) {
            return (
                <span><strong>Last Data: </strong>{old_data}</span>
            )
        }

        return "";
    }

    checkAlleryAndComorbidites(old_data, new_data) {
        if (old_data.length <= 0 && new_data && new_data.length > 0) {
            return (
                <span><strong>New: </strong>{new_data.join(',')}</span>
            )
        } else if (!_.isEqual(old_data, new_data)) {
            return (
                <span><strong>Last Data: </strong>{old_data.join(',')}</span>
            )
        }

        return "";
    }

    render() {
        this.validator.purgeFields();
        const { new_is_no, new_is_private_sagicore, new_is_private_medicus, new_is_private_canopy, new_is_jadep, new_is_nhf, new_is_goj, new_is_other, old_profile_pic_url, new_profile_pic_url } = this.state;
        return (<div>
            <Helmet>
                <title>Edit Patient</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">Confirm Edit Patient</h4>
                    <div className="content-wrapper edit-patient">
                        <Alert />
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <fieldset className="form-group">
                                        <label htmlFor="title">Title*</label>
                                        <select className="form-control" value={this.state.new_title} id="new_title" name="new_title" onChange={this.onChange}>
                                            <option value="" disabled>Select title</option>
                                            {Config.name_title.map(function (key, idx) {
                                                return <option className="travelcompany-input" key={idx} value={key}>
                                                    {key}
                                                </option>;
                                            })}
                                        </select>
                                        {this.validator.message('new_title', this.state.new_title, 'required')}
                                        {this.checkOldAndNewPatientData(this.state.old_title, this.state.new_title)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-7">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_first_name">First Name*</label>
                                        <input type="text" className="form-control" name="new_first_name" value={this.state.new_first_name} id="new_first_name" onChange={this.onChange} />
                                        {this.validator.message('new_first_name', this.state.new_first_name, ['required', 'string', { regex: personNameRegex() }])}
                                        {this.checkOldAndNewPatientData(this.state.old_first_name, this.state.new_first_name)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-7">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_middle_name">Middle Name</label>
                                        <input type="text" className="form-control" value={this.state.new_middle_name} id="new_middle_name" name="new_middle_name" onChange={this.onChange} />
                                        {this.validator.message('new_middle_name', this.state.new_middle_name, ['string', { regex: personNameRegex() }])}
                                        {this.checkOldAndNewPatientData(this.state.old_middle_name && this.state.old_middle_name, this.state.new_middle_name && this.state.new_middle_name)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-7">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_last_name">Last Name*</label>
                                        <input type="text" className="form-control" value={this.state.new_last_name} id="new_last_name" name="new_last_name" onChange={this.onChange} />
                                        {this.validator.message('new_last_name', this.state.new_last_name, ['required', 'string', { regex: personNameRegex() }])}
                                        {this.checkOldAndNewPatientData(this.state.old_last_name, this.state.new_last_name)}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_father_name">Father Name</label>
                                        <input type="text" className="form-control" value={this.state.new_father_name} id="new_father_name" name="new_father_name" onChange={this.handleChange} />
                                        {this.validator.message('new_father_name', this.state.new_father_name, ['string', { regex: personNameRegex() }])}
                                        {this.checkOldAndNewPatientData(this.state.old_father_name, this.state.new_father_name)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_mother_name">Mother Name</label>
                                        <input type="text" className="form-control" value={this.state.new_mother_name} id="new_mother_name" name="new_mother_name" onChange={this.handleChange} />
                                        {this.validator.message('new_mother_name', this.state.new_mother_name, ['string', { regex: personNameRegex() }])}
                                        {this.checkOldAndNewPatientData(this.state.old_mother_name, this.state.new_mother_name)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_maiden_name">Mother's Maiden Name*</label>
                                        <input type="text" className="form-control" value={this.state.new_maiden_name} id="new_maiden_name" name="new_maiden_name" onChange={this.onChange} />
                                        {this.validator.message('new_maiden_name', this.state.new_maiden_name, 'required|string')}
                                        {this.checkOldAndNewPatientData(this.state.old_maiden_name, this.state.new_maiden_name)}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_gender">Gender*</label>
                                        <select className="form-control" id="control" name="new_gender" onChange={this.onChange} value={this.state.new_gender}>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {this.validator.message('new_gender', this.state.new_gender, 'required')}
                                        {this.checkOldAndNewPatientData(this.state.old_gender, this.state.new_gender)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_date_of_birth">Date Of Birth*</label>
                                        <input type="date" className="form-control" value={this.state.new_date_of_birth} id="new_date_of_birth" name="new_date_of_birth" onChange={this.onChange} />
                                        {this.validator.message('new_date_of_birth', this.state.new_date_of_birth, 'required')}
                                        {this.checkOldAndNewPatientData(this.state.old_date_of_birth, this.state.new_date_of_birth)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_place_of_birth">Place Of Birth*</label>
                                        <input type="text" className="form-control" value={_.capitalize(this.state.new_place_of_birth)} id="new_place_of_birth" name="new_place_of_birth" onChange={this.onChange} />
                                        {this.validator.message('new_place_of_birth', this.state.new_place_of_birth, 'required|string')}
                                        {this.checkOldAndNewPatientData(this.state.old_place_of_birth, this.state.new_place_of_birth)}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_email">Email</label>
                                        <input type="text" className="form-control" value={this.state.new_email} id="new_email" name="new_email" onChange={this.onChange} />
                                        {this.validator.message('email', this.state.new_email, 'email')}
                                        {this.checkOldAndNewPatientData(this.state.old_email, this.state.new_email)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_contact_number">Contact Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_contact_number} id="new_contact_number" name="new_contact_number" onChange={this.onChange} />
                                        {this.validator.message('new_contact_number', this.state.new_contact_number, 'required|phone')}
                                        {this.checkOldAndNewPatientData(this.state.old_contact_number, this.state.new_contact_number)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_alt_contact_number">Alternate Number/Landline Number</label>
                                        <input type="text" className="form-control" value={this.state.new_alt_contact_number ? this.state.new_alt_contact_number : ''} id="new_alt_contact_number" name="new_alt_contact_number" onChange={this.onChange} />
                                        {this.validator.message('new_alt_contact_number', this.state.new_alt_contact_number, 'phone')}
                                        {this.checkOldAndNewPatientData(this.state.old_alt_contact_number, this.state.new_alt_contact_number)}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_blood_group">Blood Group</label>
                                        <select className="form-control" value={this.state.new_blood_group} id="new_blood_group" name="new_blood_group" onChange={this.onChange}>
                                            <option value="">Unknown</option>
                                            {Object.keys(Config.BLOODGROUP).map(function (key, idx) {
                                                return <option className="travelcompany-input" key={idx} value={Config.BLOODGROUP[key]}>
                                                    {Config.BLOODGROUP[key]}
                                                </option>;
                                            })}
                                        </select>
                                        {this.validator.message('new_blood_group', this.state.new_blood_group, 'string')}
                                        {this.checkOldAndNewPatientData(this.state.old_blood_group, this.state.new_blood_group)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_height">Height</label>
                                        <input type="number" name="new_height" className="form-control" value={this.state.new_height ? parseFloat(this.state.new_height) : ''} onChange={this.onChange} min="0" autoComplete="off" placeholder="in cm" />
                                        {this.validator.message('new_height', this.state.new_height, 'numeric|min:0,num')}
                                        {this.checkOldAndNewPatientData(this.state.old_height, this.state.new_height)}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-24">
                                    <fieldset className="form-group">
                                        <label htmlFor="insurance_type">Insurance Type*</label>
                                        <div className="checkbox-option-list">
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-no'} name="new_insurance_type" value="no" onChange={this.handleInsuranceTypeChange} checked={new_is_no} />
                                                <label className="form-check-label" htmlFor={'insurance_type-no'}>No</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" checked={this.state.new_is_jadep} id={'insurance_type-jadep'} name="insurance_type" value="jadep" disabled={this.state.new_isJadepDisabled} onChange={this.handleInsuranceTypeChange} />
                                                <label htmlFor={'insurance_type-jadep'}>JADEP</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" checked={this.state.new_is_nhf} id={'insurance_type-nhf'} name="insurance_type" value="nhf" onChange={this.handleInsuranceTypeChange} />
                                                <label htmlFor={'insurance_type-nhf'}>NHF</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" checked={new_is_goj} id={'insurance_type-goj'} name="insurance_type" value="goj" onChange={this.handleInsuranceTypeChange} />
                                                <label htmlFor={'insurance_type-goj'}>GOJ</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-sagicore'} name="insurance_type" value="private-insurance-sagicore" onChange={this.handleInsuranceTypeChange} checked={new_is_private_sagicore} />
                                                <label htmlFor={'insurance_type-private-insurance-sagicore'}>Private Insurance Sagicor</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-medicus'} name="insurance_type" value="private-insurance-medicus" onChange={this.handleInsuranceTypeChange} checked={new_is_private_medicus} />
                                                <label htmlFor={'insurance_type-private-insurance-medicus'}>Private Insurance Medicus</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-canopy'} name="insurance_type" value="private-insurance-canopy" onChange={this.handleInsuranceTypeChange} checked={new_is_private_canopy} />
                                                <label htmlFor={'insurance_type-private-insurance-canopy'}>Private Insurance Canopy</label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-other'} name="insurance_type" value="other" onChange={this.handleInsuranceTypeChange} checked={new_is_other} />
                                                <label htmlFor={'insurance_type-other'}>Other</label>
                                            </div>
                                        </div>
                                        {this.validator.message('new_insurance_type', this.state.new_insurance_type, 'insurance_type')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.new_is_jadep && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_jadep_number">Jadep Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_jadep_number} id="new_jadep_number" name="new_jadep_number" onChange={this.onChange} />
                                        {this.state.new_is_jadep && this.state.new_insurance_type.includes('jadep') ?
                                            this.validator.message('new_jadep_number', this.state.new_jadep_number, 'required|string|max:100') : ''
                                        }
                                        {this.checkOldAndNewPatientData(this.state.old_is_jadep, this.state.new_is_jadep)}
                                    </fieldset>
                                </div>}

                                {this.state.new_is_nhf && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_nhf_number">NHF Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_nhf_number} id="new_nhf_number" name="new_nhf_number" onChange={this.onChange} />
                                        {this.state.new_is_nhf && this.state.new_insurance_type.includes('nhf') ? this.validator.message('new_nhf_number', this.state.new_nhf_number, 'required|string|max:100') : ''}
                                        {this.checkOldAndNewPatientData(this.state.old_nhf_number, this.state.new_nhf_number)}
                                    </fieldset>
                                </div>}

                                {this.state.new_is_goj && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_goj_number">GOJ Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_goj_number} id="new_goj_number" name="new_goj_number" onChange={this.onChange} />
                                        {this.state.new_is_goj && this.state.new_insurance_type.includes('goj') ? this.validator.message('new_goj_number', this.state.new_goj_number, 'required|string|max:100') : ''}
                                        {this.checkOldAndNewPatientData(this.state.old_goj_number, this.state.new_goj_number)}
                                    </fieldset>
                                </div>}

                                {this.state.new_is_private_sagicore && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_goj_number">Sagicor Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_private_insurance_sagicore_number} id="sagicore_number" name="new_private_insurance_sagicore_number" onChange={this.onChange} />
                                        {this.validator.message('new_private_insurance_sagicore_number', this.state.new_private_insurance_sagicore_number, 'required|string|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_private_insurance_sagicore_number, this.state.new_private_insurance_sagicore_number)}
                                    </fieldset>
                                </div>}

                                {this.state.new_is_private_medicus && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_goj_number">Medicus Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_private_insurance_medicus_number} id="medicus_number" name="new_private_insurance_medicus_number" onChange={this.onChange} />
                                        {this.validator.message('new_private_insurance_medicus_number', this.state.new_private_insurance_medicus_number, 'required|string|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_private_insurance_medicus_number, this.state.new_private_insurance_medicus_number)}
                                    </fieldset>
                                </div>}

                                {this.state.new_is_private_canopy && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_goj_number">Canopy Number*</label>
                                        <input type="text" className="form-control" value={this.state.new_private_insurance_canopy_number} id="canopy_number" name="new_private_insurance_canopy_number" onChange={this.onChange} />
                                        {this.validator.message('new_private_insurance_canopy_number', this.state.new_private_insurance_canopy_number, 'required|string|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_private_insurance_canopy_number, this.state.new_private_insurance_canopy_number)}
                                    </fieldset>
                                </div>}
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_address_line_1">Address 1*</label>
                                        <textarea className="form-control" value={this.state.new_address_line_1 ? this.state.new_address_line_1 : ''} id="new_address_line_1" rows="2" name="new_address_line_1" onChange={this.onChange} maxLength="255" />
                                        {this.validator.message('new_address_line_1', this.state.new_address_line_1, 'required|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_address_line_1, this.state.new_address_line_1)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_address_line_2">Address 2</label>
                                        <textarea rows="2" className="form-control" value={this.state.new_address_line_2 ? this.state.new_address_line_2 : ''} id="new_address_line_2" name="new_address_line_2" onChange={this.onChange} maxLength="255" />
                                        {this.validator.message('new_address_line_2', this.state.new_address_line_2, 'string|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_address_line_2, this.state.new_address_line_2)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_address_line_3">Address 3</label>
                                        <textarea rows="2" className="form-control" value={this.state.new_address_line_3 ? this.state.new_address_line_3 : ''} id="new_address_line_3" name="new_address_line_3" onChange={this.onChange} maxLength="255" />
                                        {this.validator.message('new_address_line_3', this.state.new_address_line_3, 'string|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_address_line_3, this.state.new_address_line_3)}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="country">Country*</label>
                                        <Select options={this.state.countries} id="country" value={this.state.new_country ? { value: this.state.new_country, label: this.state.new_country } : null} onChange={(newValue) => this.setCountry(newValue)} />
                                        {this.validator.message('country', this.state.new_country, 'required')}
                                    </fieldset>
                                </div>
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_parish">Parish*</label>
                                        {
                                            this.state.new_country === "Jamaica" ? (
                                                <select
                                                    className="form-control"
                                                    value={this.state.new_parish || ""}
                                                    id="parish"
                                                    name="new_parish"
                                                    onChange={this.onChange}
                                                >
                                                    <option value="" disabled>Select Parish</option>
                                                    {Object.keys(Config.parish).map((key, idx) => (
                                                        <option className="travelcompany-input" key={idx} value={key}>
                                                            {Config.parish[key]}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.new_parish || ""}
                                                    id="parish"
                                                    name="new_parish"
                                                    onChange={this.onChange}
                                                />
                                            )
                                        }
                                        {this.validator.message('new_parish', this.state.new_parish, 'required')}
                                        {this.checkOldAndNewPatientData(this.state.old_parish, this.state.new_parish)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="city">Community*</label>
                                        {
                                            this.state.new_country === "Jamaica" ? (
                                                <CreatableSelect
                                                    isClearable
                                                    options={this.state.selectedParisCommunity}
                                                    value={this.state.new_city ? { value: this.state.new_city, label: this.state.new_city } : null}
                                                    onChange={(newValue) => this.setCommunity(newValue)}
                                                    onCreateOption={this.createCommunity}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.new_city || ""}
                                                    id="city"
                                                    name="new_city"
                                                    onChange={this.onChange}
                                                />
                                            )
                                        }
                                        {this.validator.message('new community', this.state.new_city, 'required|string|max:100')}
                                        {this.checkOldAndNewPatientData(this.state.old_city, this.state.new_city)}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_pincode">Zip Code</label>
                                        <input type="text" className="form-control" value={this.state.new_pincode ? this.state.new_pincode : ''} id="new_pincode" name="new_pincode" onChange={this.onChange} />
                                        {this.validator.message('new_pincode', this.state.new_pincode, 'alpha_num')}
                                        {this.checkOldAndNewPatientData(this.state.old_pincode, this.state.new_pincode)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_kin_name">Next of Kin</label>
                                        <input type="text" className="form-control" value={this.state.new_kin_name ? this.state.new_kin_name : ''} id="new_kin_name" name="new_kin_name" onChange={this.onChange} />
                                        {this.checkOldAndNewPatientData(this.state.old_kin_name, this.state.new_kin_name)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_kin_relation">Relationship with Kin</label>
                                        <input type="text" className="form-control" value={this.state.new_kin_relation ? this.state.new_kin_relation : ''} id="new_kin_relation" name="new_kin_relation" onChange={this.onChange} />
                                        {this.checkOldAndNewPatientData(this.state.old_kin_relation, this.state.new_kin_relation)}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_kin_number">Kin's Contact Number</label>
                                        <input type="text" className="form-control" value={this.state.new_kin_number ? this.state.new_kin_number : ''} id="new_kin_number" name="new_kin_number" onChange={this.onChange} />
                                        {this.validator.message('new_kin_number', this.state.new_kin_number, 'phone')}
                                        {this.checkOldAndNewPatientData(this.state.old_kin_number, this.state.new_kin_number)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_nearest_police_station">Nearest Police Station</label>
                                        <input type="text" className="form-control" value={this.state.new_nearest_police_station ? this.state.new_nearest_police_station : ''} id="new_nearest_police_station" name="new_nearest_police_station" onChange={this.onChange} />
                                        {this.checkOldAndNewPatientData(this.state.old_nearest_police_station, this.state.new_nearest_police_station)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_occupation">Occupation</label>
                                        <input type="text" className="form-control" value={this.state.new_occupation ? this.state.new_occupation : ''} id="new_occupation" name="new_occupation" onChange={this.onChange} />
                                        {this.checkOldAndNewPatientData(this.state.old_occupation, this.state.new_occupation)}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_allergy">Any Known Allergies</label>
                                        <textarea className="form-control" name="new_allergy" value={this.state.new_allergy} onChange={this.handleTagsChange} placeholder="Type allergy"></textarea>
                                        {this.checkAlleryAndComorbidites(this.state.old_allergy, this.state.new_allergy)}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="new_comorbidities">Comorbidities</label>
                                        <textarea className="form-control" name="new_comorbidities" value={this.state.new_comorbidities} onChange={this.handleTagsChange} placeholder="Type comorbidities"></textarea>
                                        {this.checkAlleryAndComorbidites(this.state.old_comorbidities, this.state.new_comorbidities)}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="profile_pic">Profile Picture</label>
                                        {old_profile_pic_url === new_profile_pic_url && !_.isEmpty(this.state.old_profile_pic_url) &&
                                            <div className="profile-pic">
                                                <img src={old_profile_pic_url} alt="" />
                                            </div>
                                        }

                                        {_.isEmpty(old_profile_pic_url) && <div className="profile-pic">
                                            <img src="/images/user.svg" alt="" />
                                        </div>}

                                        {old_profile_pic_url !== new_profile_pic_url && old_profile_pic_url && old_profile_pic_url.length > 0 && (
                                            <>
                                                <br />
                                                <strong><label htmlFor="profile_pic">Last Data:</label></strong>
                                                <div className="profile-pic">
                                                    <img src={this.state.old_profile_pic_url} alt="" />
                                                </div>
                                            </>
                                        )}

                                        {!_.isEmpty(old_profile_pic_url) && _.isEmpty(new_profile_pic_url) && (<>
                                            <br />
                                            <strong><label htmlFor="profile_pic">New:</label></strong>
                                            <div className="profile-pic">
                                                <img src="/images/user.svg" alt="" />
                                            </div>
                                        </>)}

                                        {old_profile_pic_url !== new_profile_pic_url && new_profile_pic_url && new_profile_pic_url.length > 0 && (
                                            <>
                                                <br />
                                                <strong><label htmlFor="profile_pic">New:</label></strong>
                                                <div className="profile-pic">
                                                    <img src={this.state.new_profile_pic_url} alt="" />
                                                </div>
                                            </>
                                        )}

                                        <input className="hidden" id="profile-pic-upload" type="file" name="file" onChange={this.onFileChange} accept='.jpeg,.jpg,.png' />
                                        {!_.isEmpty(this.state.new_profile_pic_url) ? <>
                                            <button type="button" className="btn btn-secondry profile-pic-btn" onClick={this.deleteProfile}>Remove</button>
                                            <label htmlFor="profile-pic-upload" className="btn btn-secondry profile-pic-btn mb-0">Change Image</label>
                                        </> : <><label htmlFor="profile-pic-upload" className="profile-pic-upload ml-2">Choose File</label></>}
                                    </fieldset>
                                </div>


                            </div>
                            <div className="row">
                                <div className="col-sm-24">
                                    <button type="button" className="btn btn-primary" disabled={this.state.disableSubmit} onClick={() => { this.setState({ show_model: true }) }}>Submit</button>
                                    <Link to="/patients/search" className="btn btn-secondry">Cancel</Link>
                                </div>
                            </div>
                        </form>
                        <PatientProfileUpdateModal showModal={this.state.show_model} handleModal={this.handlePatientProfileUpdate} />
                    </div>
                </div>
            </div>
        </div >
        );
    }
}

const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    patientupdate: PatientAction.update,
};

export default connect(mapStateToProps, mapActionsToProps)(EditPatientConfirmPage);
