import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class CountryService {
    static listAll() {
        const requestOptions = {
            headers: getApiHeader()
        };
        return axios.get(apiRoute('/v1/countries'), requestOptions);
    }
}

export default CountryService;